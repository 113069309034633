@keyframes title-fade-in {
    0% { opacity: 0; }
    50% { opacity: 50%; }
    100% { opacity: 1; }
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  align-items: center;
}

.title-jumbo {
    /* background-image: url("../images/golf-morning-pano.jpeg"); */
    /* background-repeat: no-repeat; */
    background-size: 100%;
    color: #519B31;
    -webkit-text-stroke: 1px #000000;
    background-color: #FFF;
}

.title-main {
    font-weight: bold;
}

.subtitle-main {
    font-weight: bold;
}

.title-fade-animate {
    animation-name: title-fade-in;
    animation-duration: 3.0s;
    animation-fill-mode: forwards;
    animation-delay: 0.5s;
    opacity: 0;
}

.subtitle-fade-animate {
    animation-name: title-fade-in;
    animation-duration: 3.0s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.hidden {
    visibility: hidden;
}
